import classes from "./Box.module.css";
import { useEffect, useState, useContext } from "react";
import { useStore } from "../../hooks/store";

const Box = ({ typeId, className, onClick, children }) => {
  const [bgColor, setBgColor] = useState(null);
  const state = useStore()[0];

  useEffect(() => {
    if (state.types.length > 0 && typeId) {
      const indexP = state.types.findIndex((item) => item.id === typeId);
      if (indexP !== -1) {
        setBgColor({ backgroundColor: state.types[indexP].color });
      }
    }
  }, [state.types, typeId]);

  // console.log(typeId)
  // console.log(bgColor)

  return (
    <div
      className={[classes.box, className].join(" ")}
      style={bgColor ? bgColor : null}
      onClick={() => onClick && onClick()}
    >
      {children}
    </div>
  );
};

export default Box;
